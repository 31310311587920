import React from 'react';

function VotingList({ votings, selectedSid, onSelect, onRefresh, timeLeft}) {
  return (
    <div className="voting-list">
      {votings.map((voting) => (
        <button
          key={voting.sid}
          onClick={() => onSelect(voting.sid)}
          className={selectedSid === voting.sid ? 'selected' : ''}
        >
          {voting.surveyls_title}
          {/*`${voting.sid} - ${voting.surveyls_title}`*/}
        </button>
      ))}
      <div className='grow-spacer'></div>
      <button onClick={onRefresh} className="refresh-button">Obnovit</button>
      <p>Automatické obnovení za {timeLeft} sekund.</p>
    </div>
  );
}

export default VotingList;
