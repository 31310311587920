// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import VotingList from './VotingList';
import VotingDetail from './VotingDetail';

const autoRefreshIntervalInSec = 15;
const apiUrl = "https://vysledky.jsmetransparent.cz/api";

function App() {
  const [votings, setVotings] = useState([]);
  const [selectedSid, setSelectedSid] = useState(null);
  const [details, setDetails] = useState('');
  const [timeLeft, setTimeLeft] = useState(autoRefreshIntervalInSec);

  const fetchVotings = () => {
    fetch(`${apiUrl}/hlasovani`)
      .then((response) => response.json())
      .then((data) => {
        setVotings(data);
        setTimeLeft(autoRefreshIntervalInSec);
      })
      .catch((error) => console.error('Chyba při načítání dat:', error));
  };

  useEffect(() => {
    fetchVotings();
  }, []);

  useEffect(() => {
    if (selectedSid) {
      fetch(`${apiUrl}/hlasovani-html/${selectedSid}`)
        .then((response) => response.text())
        .then((data) => setDetails(data))
        .catch((error) => console.error('Chyba při načítání detailu:', error));
    } else {
      setDetails('');
    }
  }, [selectedSid]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          fetchVotings();
          return autoRefreshIntervalInSec;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleButtonClick = (sid) => {
    setSelectedSid(sid);
  };

  const handleRefresh = () => {
    fetchVotings();
  };

  return (
    <div className="App">
      <h1>Přehled hlasování</h1>
      <div className="container">
        <VotingList
          votings={votings}
          selectedSid={selectedSid}
          onSelect={handleButtonClick}
          onRefresh={handleRefresh}
          timeLeft={timeLeft}
        />
        <VotingDetail
          selectedSid={selectedSid}
          details={details}
        />
      </div>
    </div>
  );
}

export default App;
