import React from 'react';

function VotingDetail({ selectedSid, details }) {
  return (
    <div className="voting-detail">
      {selectedSid ? (
        <p>
          <div dangerouslySetInnerHTML={{__html: details}}></div>
        </p>
      ) : (
        <p>Vlevo vyberte hlasování pro které chcete zobrazit výsledky</p>
      )}
    </div>
  );
}

export default VotingDetail;
